import React, { useState, useEffect } from "react";
import VideoPlayer from "../../component/videoplayer/index.jsx";
import scrollGif from "../../assets/fleche/arrow-down.png"; 
import image1 from "../../assets/gallerie/vue-mer2.webp";
import image2 from "../../assets/gallerie/grappe-seule.webp";
import image3 from "../../assets/gallerie/boutique.webp";
import image4 from "../../assets/gallerie/apero5.webp";
import Wine from "../Wine/index.jsx";
import History from "../History/index.jsx";
import Event from "../Event/index.jsx";
import News from "../News/index.jsx";
import Contact from "../Contact/index.jsx";
import Footer from "../Footer/index.jsx";
import LegalMention from "../LegalMention/index.jsx";

function Home({ t, isModalOpen, handleConfirmation, handleDenial }) {
    const [activeSection, setActiveSection] = useState("home");

    const handleModalOpen = (section) => setActiveSection(section);
    const handleModalClose = () => setActiveSection("home");

    const [isLegalMentionVisible, setIsLegalMentionVisible] = useState(false);

    const handleShowLegalMention = () => {
        setIsLegalMentionVisible(true);
    };
    
    const handleCloseLegalMention = () => {
        setIsLegalMentionVisible(false);
    };
    useEffect(() => {
        if (isLegalMentionVisible) {
            document.getElementById("legal-mention")?.scrollIntoView({ behavior: "smooth" });
        }
    }, [isLegalMentionVisible]);

    const getTitleLinkStyle = (section) => {
        return {
            display: activeSection === section ? 'none' : 'block',
        };
    };
    

    return(
        <>
        {isModalOpen && (
            <div className="modal-majority">
                <div className="modal-content">
                    <p>{t('majority')}</p>
                    <button onClick={handleConfirmation}>{t('majority-yes')}</button>
                    <button onClick={handleDenial}>{t('majority-no')}</button>
                </div>
            </div>
        )}
        <div className={`container ${isModalOpen ? 'blurred' : ''}`}>
            <div className="container">     
                    <div id="wine" className="section video">
                        <div className="grid">
                            <div className="content">
                                <VideoPlayer/>
                                {activeSection === 'Wine' && (
                                    <div className="modal">
                                        <Wine onClose={handleModalClose} />
                                    </div> 
                                )}
                               {!isModalOpen && (
                                <a href="#history">
                                    <img
                                        src={scrollGif} 
                                        alt="Scroll Down"
                                        className="scroll-indicator"
                                        id="scroll-arrow"
                                    />
                                </a>
                                )}

                            </div>
                            
                            <div className="title-link" style={getTitleLinkStyle('Wine')}>
                            {activeSection !== 'Wine' && (
                                <button onClick={() => handleModalOpen('Wine')}>
                                    <h2>{t('ExploreOurCellar')}</h2>
                                </button> 
                            )}
                            </div>       
                        </div>
                    </div>

                <div id="history" className="section image">
                    <div className="grid">
                        <div className="title-link" style={getTitleLinkStyle('History')}>
                        {activeSection !== 'History' && (
                            <button onClick={() => handleModalOpen('History')}>
                            <h2>{t('SevenHundredYears')}</h2>
                            </button> 
                         )}
                        </div>
                        <div className="content">
                            <img className="background" src={image2} alt="notre histoire"/>
                            {activeSection === 'History' && (
                            <div className="modal">
                                <History onClose={handleModalClose}  />
                            </div>
                        )}
                        {!isModalOpen && (
                        <a href="#event">
                            <img
                                src={scrollGif} 
                                alt="Scroll Down"
                                className="scroll-indicator"
                                id="scroll-arrow"
                            />
                        </a>
                        )}
                        </div>          
                    </div>
                </div>

                <div id="event" className="section image">
                    <div className="grid">
                        <div className="title-link" style={getTitleLinkStyle('Event')}>
                        {activeSection !== 'Event' && (
                            <button onClick={() => handleModalOpen('Event')}>
                                <h2>{t('TastingInTheVineyards')}</h2>
                            </button> 
                            )} 
                        </div>
                        
                        <div className="content">
                        <img className="background" src={image4} alt="vue sur mer"/>
                            {activeSection === 'Event' && (
                            <div className="modal">
                                <Event onClose={handleModalClose}  />
                            </div>
                        )}
                        {!isModalOpen && (
                        <a href="#news">
                            <img
                                src={scrollGif} 
                                alt="Scroll Down"
                                className="scroll-indicator"
                                id="scroll-arrow"
                            />
                        </a>
                        )}
                        </div>          
                    </div>
                </div>

                <div id="news" className="section image">
                    <div className="grid">
                        <div className="title-link" style={getTitleLinkStyle('News')}>
                        {activeSection !== 'News' && (
                        <button onClick={() => handleModalOpen('News')}>
                                <h2>{t('DomainNews')}</h2>
                            </button> 
                        )}
                        </div>
                        
                        <div className="content">
                        <img className="background" src={image1} alt="gamme"/>
                            {activeSection === 'News' && (
                            <div className="modal">
                                <News  onClose={handleModalClose}  />
                            </div>
                        )}
                        {!isModalOpen && (
                        <a href="#contact">
                            <img
                                src={scrollGif} 
                                alt="Scroll Down"
                                className="scroll-indicator"
                                id="scroll-arrow"
                            />
                        </a>
                        )}
                        </div>          
                    </div>
                </div>

                <div id="contact" className="section image">
                    <div className="grid">
                        <div className="title-link" style={getTitleLinkStyle('Contact')}>
                        {activeSection !== 'Contact' && (
                                <button onClick={() => handleModalOpen('Contact')}>
                                    <h2>{t('BookYourVisit')}</h2>
                                </button>
                            )}
                        </div>
                        <div className="content">
                        <img className="background" src={image3} alt="boutique"/>
                            {activeSection === 'Contact' && (
                                <div className="modal">
                                    <Contact  onClose={handleModalClose} />
                                </div>
                            )}
                            {!isModalOpen && (
                            <a href="#footer">
                            <img
                                src={scrollGif} 
                                alt="Scroll Down"
                                className="scroll-indicator"
                                id="scroll-arrow"
                            />
                        </a>
                        )}
                        </div>       
                    </div>
                </div>
                
                {isLegalMentionVisible && (
                <div id="legal-mention" className={`section ${isLegalMentionVisible ? 'show' : 'hidden'}`}>
                    <div className="grid">
                        
                        <div className="content history">
                            <LegalMention />
                            <div className="close-modal mention-close">
                            <button onClick={handleCloseLegalMention}>
                                <h5>Fermer</h5>
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
                )}

                <div className="section last">
                <Footer onShowLegalMention={handleShowLegalMention} />
                </div>
            </div>
        </div>
        </>
    )
}
export default Home;