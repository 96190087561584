import React from "react";

function LegalMention() {
    return (
        <section className="legal-mention">
            <h3>Mentions légales</h3>

            <article>
                <h4>1. Présentation du site</h4>
                <p>
                    Conformément à l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique,
                    il est précisé aux utilisateurs du site <strong>www.cagueloup.fr</strong> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
                </p>
                <div>
                    <h5>Propriétaire :</h5>
                    <p>Vignobles PREBOST – 267, chemin de la Verdelaise, 83270 Saint-Cyr-sur-Mer, France</p>
                </div>
                <div>
                    <h5>Créateur :</h5>
                    <p>
                        <a href="https://florian-denis-dev.netlify.app/" target="_blank" rel="noopener noreferrer">
                            Florian DENIS - Developpeur Freelance
                        </a>
                    </p>
                </div>
                <div>
                    <h5>Responsable de la publication :</h5>
                    <p>Famille Prébost – domainedecagueloup@gmail.com</p>
                </div>
                <div>
                    <h5>Hébergeur :</h5>
                    <p>OVH – 2 rue Kellermann, 59100 Roubaix, France</p>
                </div>
            </article>

            <article>
                <h4>2. Technologies utilisées</h4>
                <p>
                    Le site <strong>www.cagueloup.fr</strong> a été conçu avec les technologies suivantes :
                </p>
                <ul>
                    <li>Langages : HTML, CSS, SCSS, JavaScript</li>
                    <li>Frameworks : React, Next.js</li>
                    <li>Base de données : MongoDB</li>
                    <li>Hébergement : OVH, AWS</li>
                </ul>
            </article>

            <article>
                <h4>3. Réglementation liée aux domaines viticoles</h4>
                <p>
                    La vente et la consommation de boissons alcoolisées sont soumises à la législation en vigueur en France.
                    En accédant au site <strong>www.cagueloup.fr</strong>, l'utilisateur reconnaît avoir l'âge légal requis dans son pays de résidence pour la consommation d’alcool.
                </p>
                <p>
                    L'abus d'alcool est dangereux pour la santé, à consommer avec modération. Pour plus d’informations,
                    veuillez consulter le site <a href="https://www.alcool-info-service.fr" target="_blank" rel="noopener noreferrer">Alcool Info Service</a>.
                </p>
            </article>

            <article>
                <h4>4. Gestion des données personnelles et cookies</h4>
                <p>
                    Conformément au Règlement Général sur la Protection des Données (RGPD), l'utilisateur dispose d'un droit d'accès, de modification et de suppression des informations personnelles collectées.
                </p>
                <p>
                    Ce site utilise des cookies afin d’améliorer l’expérience utilisateur et réaliser des statistiques de visite.
                    L’utilisateur peut paramétrer son navigateur pour refuser les cookies.
                </p>
            </article>

            <article>
                <h4>5. Propriété intellectuelle</h4>
                <p>
                    Tous les éléments du site <strong>www.cagueloup.fr</strong> sont protégés par la législation relative à la propriété intellectuelle.
                    Toute reproduction ou représentation, en tout ou partie, sans l’autorisation expresse des Vignobles PREBOST est interdite.
                </p>
            </article>

            <article>
                <h4>6. Droit applicable</h4>
                <p>
                    Toute utilisation du site <strong>www.cagueloup.fr</strong> est soumise au droit français.
                    En cas de litige, les tribunaux compétents seront ceux du ressort du siège social du propriétaire du site.
                </p>
            </article>
        </section>
    );
}

export default LegalMention;