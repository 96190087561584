import React from "react";
import { useTranslation } from "react-i18next";

function Info({ wine }) {
  const { t } = useTranslation();

  if (!wine) return null;

  return (
    <div className="info">
      <h3>{t(wine.title_key)}</h3>
      <h4>{t(wine.denomination_key)}</h4>
      
      <div className="info-row">
        <div className="info-title">{t('wine.Grapes')}:</div>
        <div className="info-value">{t(wine.grapes_key)}</div>
      </div>
      
      <div className="info-row">
        <div className="info-title">{t('wine.Price')}:</div>
      <div className="info-value">{wine.price} €</div>
      </div>

      <div className="info-row">
        <div className="info-title">{t('wine.Soil')}:</div>
        <div className="info-value">{t(wine.soil_key)}</div>
      </div>

      <div className="info-row">
        <div className="info-title">{t('wine.Yield')}:</div>
        <div className="info-value">{t(wine.yield_key)}</div>
      </div>

      <div className="info-row">
        <div className="info-title">{t('wine.Vinification')}:</div>
        <div className="info-value">{t(wine.vinification_key)}</div>
      </div>

      <div className="info-row">
        <div className="info-title">{t('wine.Aging')}:</div>
        <div className="info-value">{t(wine.aging_key)}</div>
      </div>

      <div className="info-row">
        <div className="info-title">{t('wine.Tasting Notes')}:</div>
        <div className="info-value">{t(wine.tasting_key)}</div>
      </div>

      <div className="info-row">
        <div className="info-title">{t('wine.Pairing')}:</div>
        <div className="info-value">{t(wine.pairing_key)}</div>
      </div>

      <div className="info-row">
        <div className="info-title">{t('wine.Use')}:</div>
        <div className="info-value">{t(wine.use_key)}</div>
      </div>

    </div>
  );
}

export default Info;


