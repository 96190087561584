import React from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";

const apiUrl = "https://vps-126b357d.vps.ovh.net"; //  http://localhost:3000

const CheckoutForm = ({ clientSecret, orderId, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  console.log("Client Secret reçu :", clientSecret);
  console.log("Stripe chargé :", stripe);
  console.log("Elements chargé :", elements);

  if (!clientSecret) {
    return <p>Chargement du paiement...</p>;
  }

  const updateOrderPaymentStatus = async (orderId) => {
    if (!orderId) {
      console.error("L'ID de la commande est manquant !");
      return false;
    }

    try {
      const response = await fetch(`${apiUrl}/api/orders/${orderId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ paymentStatus: "paid" }),
      });

      if (!response.ok) {
        console.error("Erreur lors de la mise à jour de la commande :", await response.json());
        return false;
      }

      console.log("Commande mise à jour avec succès !");
      return true;
    } catch (error) {
      console.error("Erreur réseau :", error);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js n'est pas encore chargé.");
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin, 
        },
      });

      if (error) {
        console.error("Erreur lors du paiement :", error.message);
        return;
      }

      if (paymentIntent.status === "succeeded") {
        console.log("Paiement réussi :", paymentIntent);

        const isUpdated = await updateOrderPaymentStatus(orderId);
        if (isUpdated) {
          onSuccess();
        } else {
          console.error("Erreur lors de la mise à jour de l'état du paiement.");
        }
      }
      
    } catch (err) {
      console.error("Erreur inattendue lors du paiement :", err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="formStripe">
      <p>Veuillez entrer vos informations bancaires ci-dessous :</p>
      <PaymentElement options={{ layout: "tabs" }} />
      <div className="containerButtonStripe">
        <button className="buttonToStripe" type="submit" disabled={!stripe || !elements}>
          Commander
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;
