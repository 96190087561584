import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./pages/Header/index.jsx";
import Home from "./pages/Home/index.jsx";
import LegalMention from "./pages/LegalMention/index.jsx";
import { useTranslation } from 'react-i18next';
import './i18n/i18n.js';

function App() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleConfirmation = () => {
    setIsModalOpen(false);
  };

  const handleDenial = () => {
    window.location.href = 'https://www.instagram.com/domaine_de_cagueloup_/';
  };

  return (
    <>
      <Router>
        <Header t={t} />
        <Routes>
          <Route path="/" element={<Home t={t} isModalOpen={isModalOpen} handleConfirmation={handleConfirmation} handleDenial={handleDenial} />} />
          <Route path="/mentions-legales" element={<LegalMention />} /> 
        </Routes>
      </Router>
    </>
  );
}

export default App;
