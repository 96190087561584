import React from "react";
import { useTranslation } from "react-i18next";

function Footer({ onShowLegalMention }) {
    const {t} = useTranslation();
    return(
        <>
        <footer>
            <div id="footer" className="footer">
                <p>
                    <button onClick={onShowLegalMention} id="mention-show">{t('legal-mention')}</button>
                </p> 
                <a href="https://www.instagram.com/domaine_de_cagueloup_/">
                        <i className="fa-brands fa-instagram"></i>
                        <span>Instagram</span>
                    </a>
                    <a href="https://www.facebook.com/domaineducagueloup">
                        <i className="fa-brands fa-facebook"></i>
                        <span>facebook</span>
                    </a>
            </div>
        </footer>
        </>
    )
}

export default Footer;