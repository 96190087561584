import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo/cagueloup-logo.webp';
import france from '../../assets/language/france.png';
import england from '../../assets/language/england.png';

function Header(){
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
      };
    const [isModalOpen, setIsModalOpen] = useState(false);

const toggleModal = () => {
    setIsModalOpen(prev => !prev);
};

    return(
        <>
            <header>
                <div className="left-side">
                    <img className="logo" src={logo} alt={t('logo-alt')}></img>
                    <h1 className="header">
                        {t('home_title')}
                    </h1>
                </div>
                <div className="right-side">
                    <div className="button-lang">
                        {currentLanguage === 'en' && (
                            <button className="header" onClick={() => changeLanguage('fr')} aria-label={t('switch-language')}>   
                                <img classname="lang england" src={england} alt={t('key_en')}></img>
                            </button>
                        )}
                        {currentLanguage === 'fr' && (
                            <button className="header" onClick={() => changeLanguage('en')} aria-label={t('switch-language')}>
                               <img classname="lang france" src={france} alt={t('key_fr')}></img>
                            </button>
                        )}
                    </div>
                    <div className="menu">
                    <button
                        className="menu-svg"
                        onClick={toggleModal}
                        aria-label={t(isModalOpen ? "close-menu-alt" : "menu-alt")}
                        style={{ position: "relative", zIndex: 2 }}
                    >
                    {isModalOpen ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 32 32">
                            <path d="M 8 8 L 24 24 M 8 24 L 24 8" stroke="white" strokeWidth="2" strokeLinecap="round"></path>
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 52 52">
                            <path d="M 5 9 L 5 11 L 45 11 L 45 9 L 5 9 z M 5 24 L 5 26 L 45 26 L 45 24 L 5 24 z M 5 39 L 5 41 L 45 41 L 45 39 L 5 39 z"></path>
                        </svg>
                    )}
                    </button>
                        {isModalOpen && (
                            <div className="modal-menu" style={{ position: 'absolute', zIndex: 1 }}>
                                <ul>
                                    <li><a href="#wine">{t('menu-wine')}</a></li>
                                    <li><a href="#history">{t('menu-history')}</a></li>
                                    <li><a href="#event">{t('menu-event')}</a></li>
                                    <li><a href="#news">{t('menu-news')}</a></li>
                                    <li><a href="#contact">{t('menu-contact')}</a></li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;